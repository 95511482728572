@charset "utf-8";
@import '../../scss/variables';
@import '../../scss//mixins';

.Gallery {
  margin: -$gallery-gutter-small/2;
  // Setup minimum placeholder to push footer to the right position when loading images
  min-height: 70vh;
  @include bp-medium {
    margin: -$gallery-gutter-medium/2;
  }
  @include bp-large {
    margin: -$gallery-gutter-large/2;
  }
}

.Gallery-sizer,
.Gallery-item {
  width: 50%;
  @include bp-small {
    width: 33.3%;
  }
  @include bp-large {
    width: 25%;
  }
  @include bp-xlarge {
    width: 20%;
  }
}
