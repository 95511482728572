@charset "utf-8";
@import '../../scss/variables';
@import '../../scss//mixins';

.Menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  &.open {
    .Menu-backdrop {
      opacity: 1;
    }
    .Menu-panel {
      top: 0;
      border-radius: 0 0 2rem 2rem;
    }
  }
}

.Menu-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: $color-bg-backdrop;
  opacity: 0;
  transition: opacity 0.4s ease;
  backdrop-filter: blur(3px);
}

.Menu-panel {
  width: 100%;
  height: 12rem;
  position: absolute;
  top: -12rem;
  right: 0;
  font-size: 0.75rem;
  color: $color-text-secondary;
  background-color: $color-bg-primary;
  border-radius: 0 0 50% 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.3s ease-in-out;
  h2 {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
