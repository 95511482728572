@charset "utf-8";
@import '../../scss/variables';
@import '../../scss//mixins';
@import '../../scss/animations';

.GalleryPreview {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -99;
  &.open {
    z-index: 99;
    .GalleryPreview-backdrop {
      opacity: 1;
    }
  }
}

.GalleryPreview-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: $color-bg-backdrop;
  opacity: 0;
  transition: opacity 0.4s ease;
  backdrop-filter: blur(3px);
}

.GalleryPreview-figure {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    max-width: 90%;
    max-height: 90%;
    animation: scaleUp 0.4s ease;
  }
}
