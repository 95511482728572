@charset "utf-8";
@import '../../scss/variables';
@import '../../scss//mixins';

.App {
  padding: 0 $grid-small;
  transition: filter 0.3s ease;
  color: $color-text-primary;
  background-color: $color-bg-primary;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2 * $grid-small 0;
}

.App-footer {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  color: $color-text-secondary;
  margin-top: 4rem;
  .Logo-icon {
    fill: $color-text-secondary;
  }
}

@include bp-small {
  .App {
    padding: 0 $grid-medium;
  }
  .App-header {
    padding: $grid-medium 0;
  }
}

@include bp-medium {
  .App {
    padding: 0 $grid-large;
  }
  .App-header {
    padding: $grid-large 0;
  }
}

@include bp-large {
  .App {
    padding: 0 $grid-xlarge;
  }
  .App-header {
    padding: $grid-xlarge 0;
  }
}
