@charset "utf-8";

// *********************
// ***   Keyframes   ***
// *********************

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
