@charset "utf-8";

// ***********************
// *** Color Variables ***
// ***********************

$color-bg-primary: var(--bg-primary-color);
$color-bg-backdrop: var(--bg-backdrop-color);
$color-text-primary: var(--text-primary-color);
$color-text-secondary: var(--text-secondary-color);

// ************************
// *** Layout Variables ***
// ************************

$grid-xsmall: 0.625rem;
$grid-small: 1rem;
$grid-medium: 2.5rem;
$grid-large: 3.75rem;
$grid-xlarge: 5.625rem;

// *************************
// *** Gallery Variables ***
// *************************
$gallery-gutter-small: $grid-small;
$gallery-gutter-medium: 2 * $grid-small;
$gallery-gutter-large: $grid-medium;
