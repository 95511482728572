@charset "utf-8";
@import '../../scss/variables';
@import '../../scss//mixins';

.Nav {
  display: flex;
}

.Nav-desktop {
  display: none;
  @include bp-small {
    display: block;
  }
}

.Nav-mobile {
  display: block;
  @include bp-small {
    display: none;
  }
}

svg.menu {
  display: block;
  width: 2rem;
  fill: $color-text-primary;
}
